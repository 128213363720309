export * from "ce/reducers/uiReducers/queryPaneReducer";
import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { QueryPaneReduxState as CE_QueryPaneReduxState } from "ce/reducers/uiReducers/queryPaneReducer";
import {
  handlers as ce_handlers,
  initialState,
} from "ce/reducers/uiReducers/queryPaneReducer";
import { DEBUGGER_TAB_KEYS } from "components/editorComponents/Debugger/helpers";
import { createReducer } from "utils/ReducerUtils";

export type QueryPaneReduxState = CE_QueryPaneReduxState;

export const handlers = {
  ...ce_handlers,
  [ReduxActionTypes.RUN_QUERY_MODULE_INSTANCE_INIT]: (
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any,
    action: ReduxAction<{ id: string }>,
  ): QueryPaneReduxState => {
    return {
      ...state,
      isRunning: {
        ...state.isRunning,
        [action.payload.id]: true,
      },
      debugger: {
        ...state.debugger,
        selectedTab: DEBUGGER_TAB_KEYS.RESPONSE_TAB,
        open: true,
      },
    };
  },
};

export default createReducer(initialState, handlers);
