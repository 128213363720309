export * from "ce/pages/Editor/IDE/EditorPane/Query/hooks";

import {
  useGroupedAddQueryOperations as CE_useGroupedAddQueryOperations,
  useQueryEditorRoutes as CE_useQueryEditorRoutes,
} from "ce/pages/Editor/IDE/EditorPane/Query/hooks";
import type { GroupedAddOperations } from "ce/pages/Editor/IDE/EditorPane/Query/hooks";

import { FocusEntity } from "navigation/FocusEntity";
import { groupBy } from "lodash";
import { useModuleOptions } from "@appsmith/utils/moduleInstanceHelpers";
import ModuleInstanceEditor from "@appsmith/pages/Editor/ModuleInstanceEditor";
import {
  BUILDER_CUSTOM_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
} from "@appsmith/constants/routes/appRoutes";
import { MODULE_INSTANCE_ID_PATH } from "@appsmith/constants/routes/appRoutes";
import type { UseRoutes } from "@appsmith/entities/IDE/constants";
import { EDITOR_PANE_TEXTS, createMessage } from "@appsmith/constants/messages";

export const useGroupedAddQueryOperations = (): GroupedAddOperations => {
  const [fromSourceGroup, fromBlankGroup] = CE_useGroupedAddQueryOperations();
  const moduleOperations = useModuleOptions();
  const queryModules = moduleOperations.filter(
    (fileOperations) =>
      fileOperations.focusEntityType === FocusEntity.QUERY_MODULE_INSTANCE,
  );
  const packageQueryModuleGroups = groupBy(queryModules, "focusEntityType");
  const queryModulesGroups: GroupedAddOperations = [];
  Object.entries(packageQueryModuleGroups).forEach(
    ([packageTitle, instanceOps]) => {
      queryModulesGroups.push({
        title: createMessage(EDITOR_PANE_TEXTS.queries_create_modules),
        className: `t--${packageTitle}`,
        operations: instanceOps,
      });
    },
  );

  // We still show the existing datasource group first, then add the module groups and finally the blanks group
  return [fromSourceGroup, ...queryModulesGroups, fromBlankGroup];
};

export const useQueryEditorRoutes = (path: string): UseRoutes => {
  const ceRoutes = CE_useQueryEditorRoutes(path);
  return [
    {
      key: "ModuleInstanceEditor",
      component: ModuleInstanceEditor,
      exact: true,
      path: [
        BUILDER_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_CUSTOM_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_PATH_DEPRECATED + MODULE_INSTANCE_ID_PATH,
      ],
    },
    ...ceRoutes,
  ];
};
